import React from 'react';
import Flex, { Col } from '../../Flex';
import Select2 from '../Select2';
import { calculateAverage } from './../../../utils/common';

class CalculatedAverage extends React.Component {
	constructor(props) {
		super(props);

		const [cols, options] = props.params.split('|').map((i) => i.split(','));
		let values = [];

		if (props.value) {
			try {
				values = JSON.parse(props.value);
			} catch (e) {
				values = [];
			}
		}

		this.state = {
			average: calculateAverage(values),
			cols,
			values,
			options:
				(options &&
					Array.isArray(options) &&
					options.map((item) => ({
						value: item,
						label: item,
					}))) ||
				[],
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.value !== nextProps.value) {
			return true;
		}

		if (this.props.params !== nextProps.params) {
			return true;
		}

		if (this.props.field && this.props.field.name && this.props.field.name !== nextProps.field.name) {
			return true;
		}

		return false;
	}

	onChange = (value, name) => {
		const [, j] = name.split('-');

		this.setState(
			(prevState) => {
				const { values } = prevState;
				values[j] = value;

				return {
					values,
					average: calculateAverage(values),
				};
			},
			() => {
				this.props.onChange(JSON.stringify(this.state.values), this.props.field.name);
			},
		);
	};

	render() {
		const { cols, values } = this.state;

		const padding = {
			left: 10,
			right: 10,
			bottom: 10,
			top: 10,
		};

		return (
			<Flex xs={{ align: 'center' }}>
				<Col xs={{ size: 12 }}>
					<Flex xs={{ align: 'center' }}>
						{cols &&
							cols.map((col, key) => (
								<Col xs={{ size: 10 / cols.length, ...padding, textAlign: 'left' }} key={key}>
									{col}
								</Col>
							))}
						<Col xs={{ size: 2, ...padding }}>Average</Col>
					</Flex>
					<Flex xs={{ align: 'center' }}>
						{cols &&
							cols.map((col, colKey) => (
								<Col xs={{ size: 10 / cols.length, ...padding }} key={colKey}>
									<Select2
										field={{
											name: `${this.props.field.name}-${colKey}`,
										}}
										value={values[colKey]}
										options={this.state.options}
										onChange={this.onChange}
									/>
								</Col>
							))}
						<Col xs={{ size: 2, ...padding }}>
							<strong>{this.state.average}</strong>
						</Col>
					</Flex>
				</Col>
			</Flex>
		);
	}
}

export default CalculatedAverage;
