import React from 'react';
import Icon from 'react-fa';
import ClickOutside from 'react-click-outside';

import classNames from 'classnames';
import styles from './component.module.scss';
import Flex, { Col } from './../Flex';
import { hasPerm } from '../../utils/accessControl';

class FilterWidget extends React.Component {
	onClick = (userId, type) => this.props.onClick(userId, type);

	render() {
		const { isOpen, isAdmin, widgetItems, closeFilterWidgetIfOpen } = this.props;

		return (
			<ClickOutside onClickOutside={closeFilterWidgetIfOpen}>
				<div
					className={classNames(styles.root, {
						[styles.open]: isOpen,
					})}
				>
					<div style={{ padding: '5px' }}>Widgets:</div>
					<ul>
						{widgetItems
							.filter((item) => {
								// let foundItem = menu.filter(m => m.name == item.name).shift();
								// if(foundItem){
								if (typeof item.permission === 'undefined') {
									return true;
								}

								return hasPerm(item.permission);
								// }else{
								//     return hasPerm(`perm_${item.alias}_view`);
								// }
							})
							.map((item, key) => (
								<li key={key} className={styles.items}>
									<a onClick={() => this.onClick(item.id, item.widgetType)} className={styles.href}>
										<Flex xs={{ justify: 'center', align: 'center' }}>
											<Col xs={2} className={styles.icon}>
												{item.showAsWidget == 1 && <Icon name={'check'} />}
											</Col>
											<Col xs={10}>
												<div style={{ marginLeft: '2px' }}>{item.name}</div>
											</Col>
										</Flex>
									</a>
								</li>
							))}
					</ul>
				</div>
			</ClickOutside>
		);
	}
}

export default FilterWidget;
