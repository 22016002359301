import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components/Form';
import { Flex, Col, Text, Modal, Padding } from '../../../components';

class DeleteValidator extends React.Component {
	render() {
		return (
			<Modal maxWidth={540} isOpen={this.props.isOpen} onClose={this.props.onToggle} style={{ padding: 0, overflow: 'auto', width: '90%' }}>
				<Padding padding={{ top: 50, left: 20, right: 20, bottom: 50 }}>
					<Flex xs={{ justify: 'center', align: 'center' }}>
						<Text fontWeight={600} fontSize={20} style={{ textAlign: 'center', color: '#000000' }}>
							Are you sure you want to delete the selected item(s)?
						</Text>
					</Flex>
				</Padding>
				<Padding padding={{ top: 0 }}>
					<Flex xs={{ justify: 'center', align: 'center' }}>
						<Col xs={{ size: 6, justify: 'center', align: 'center' }}>
							<Button
								style={{ borderRadius: 0, height: 40, fontWeight: 'normal', background: '#d9534f', color: '#ffffff' }}
								onClick={() => {
									this.props.onDelete();
								}}
							>
								Delete
							</Button>
						</Col>
						<Col xs={{ size: 6, justify: 'center', align: 'center' }}>
							<Button
								primary
								style={{ borderRadius: 0, height: 40, fontWeight: 'normal', background: '#b6bece' }}
								onClick={() => {
									this.props.onToggle();
								}}
							>
								Cancel
							</Button>
						</Col>
					</Flex>
				</Padding>
			</Modal>
		);
	}
}

DeleteValidator.propTypes = {
	onToggle: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

export default DeleteValidator;
