import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { Button } from '../../Form';
import history from './../../../store/history';

import styles from './component.module.scss';

class AccessDenied extends React.Component {
	static propTypes = {
		history: PropTypes.shape({
			goBack: PropTypes.func.isRequired,
		}).isRequired,
	};

	handleGoHome = () => {
		this.props.history.goBack();
	};

	render() {
		if (
			history &&
			history.location &&
			history.location.pathname &&
			history.location.pathname === '/dashboard' &&
			this.props.location.pathname !== history.location.pathname
		) {
			return <Redirect to="/dashboard" />;
		}

		return (
			<div className={styles.root}>
				<div className={styles.errorPage}>
					<span className={styles.errorCode}>403</span>
					<h1 className={styles.errorHeader}>Sorry! You do not have access to this page</h1>

					<Button fullWidth={false} primary lg onClick={this.handleGoHome}>
						Take me back
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(AccessDenied);
