import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Flex, { Col } from '../../Flex';
import Image from '../../Image';

import styles from './component.module.scss';

import { getProfilePicture } from '../../../actions/user';
import { withImageUrl } from '../../../utils/withStaticUrl';
import history from './../../../store/history';

import imgNoUser from '../../../assets/images/no-user-generic.png';
import imgLogo from '../../../assets/images/con-logo.png';
import { Text } from '../../';

class SidebarUser extends React.Component {
	state = {
		avatar: '',
	};

	componentDidMount = () => {
		this.props.getProfilePicture().then((res) => {
			this.setState({
				avatar: res.payload.item,
			});
		});
	};

	onUserSetting = () => {
		//location.href = `/user-settings/update/${this.props.user.id}`;
		history.replace(`user-settings/update/${this.props.user.id}`);
	};

	opened = () => {
		const { user } = this.props;
		const { avatar } = this.state;

		return (
			<li className={styles.root}>
				<Flex xs={{ justify: 'center' }}>
					<Col xs={{ size: 12, bottom: 10 }}>
						<Image
							src={avatar ? withImageUrl(avatar) : imgNoUser}
							circle
							imageProps={{
								alt: 'Sorry! Image not available at this time',
							}}
							style={{
								width: '90px',
								height: '90px',
								margin: '0 12.5px',
								transition: 'all 2s',
							}}
						/>
					</Col>
					<Col>
						{/* <Text onClick={this.onUserSetting} style={{color: '#0052CC', fontWeight: 'bold', fontSize: '14px'}}>Welcome {user.fullName}</Text> */}
						<Link to={`/user-settings/update/${user.id}`}>
							<div className={styles.usernameWelcome}>Welcome</div>
							<span className={styles.userName}>{user.fullName}</span>
						</Link>
					</Col>
				</Flex>
			</li>
		);
	};

	closed = () => (
		<li className={styles.root}>
			<Flex xs={{ justify: 'center' }}>
				<Col xs={{ size: 12 }}>
					<Image
						src={imgLogo}
						circle
						imageProps={{
							alt: 'Sorry! Image not available at this time',
						}}
						style={{
							width: '50px',
							margin: '0 12.5px',
							transition: 'all 2s',
						}}
					/>
				</Col>
			</Flex>
		</li>
	);

	render() {
		return this.props.isClose ? this.closed() : this.opened();
	}
}

const mapDispatchToProps = (dispatch) => ({
	getProfilePicture: () => dispatch(getProfilePicture()),
});

export default connect(null, mapDispatchToProps)(SidebarUser);
