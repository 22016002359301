import React, { memo, useState } from 'react';
import styles from './component.module.scss';
import { Button } from 'components/Form';
import AddData from './AddData/AddData';
import LookupItem from './LookupItem';
import { FaEdit, FaTrash } from 'react-icons/fa';

const GridTable = ({ schema, value, onChange }) => {
	const [showEditModal, toggleEditModal] = useState(null);
	const [editMode, setEditMode] = useState(null);
	const [index, setIndex] = useState(-1);
	// console.log('schema -> ',schema);

	const addData = () => {
		setEditMode('add');
		toggleEditModal({});
	};

	const onCloseModal = () => {
		toggleEditModal(null);
	};

	const onSaveData = (data) => {
		let arr = value && Array.isArray(value) ? value : [];

		if (editMode === 'add') {
			arr.push(data);
		} else {
			arr.splice(index, 1, data);
		}
		onChange(arr);
	};

	const editRecord = (val, vIndex) => {
		setEditMode('edit');
		toggleEditModal(val);
		setIndex(vIndex);
	};

	const deleteRecord = (val, vIndex) => {
		let arr = value ? value : [];
		arr.splice(vIndex, 1);
		onChange(arr);
		toggleEditModal(null);
		setIndex(-1);
	};

	// console.log('GridTable -> ',value);
	let hasValue = !value || !Array.isArray(value) ? false : true;

	return (
		<div className={styles.root}>
			<Button onClick={addData} style={{ width: 100 }} primary>
				Add
			</Button>
			<table className={styles.table}>
				<thead>
					<tr>
						{schema && schema.fields && schema.fields.filter((f) => f.show).map((field, fIndex) => <th key={fIndex}>{field.label}</th>)}
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{hasValue &&
						value &&
						Array.isArray(value) &&
						value.map((val, vIndex) => (
							<tr key={vIndex}>
								{schema &&
									schema.fields &&
									schema.fields
										.filter((f) => f.show)
										.map((field, fIndex) => (
											<td key={fIndex}>
												<LookupItem field={field} value={val[field.id]} />
											</td>
										))}
								<td>
									<Button onClick={() => editRecord(val, vIndex)} style={{ display: 'inline-block', width: 40, marginRight: 10 }}>
										<FaEdit />
									</Button>
									<Button onClick={() => deleteRecord(val, vIndex)} style={{ display: 'inline-block', width: 40 }} danger>
										<FaTrash />
									</Button>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			{showEditModal && <AddData value={showEditModal} schema={schema} onClose={onCloseModal} onSaveData={onSaveData} />}
		</div>
	);
};

export default memo(GridTable);
