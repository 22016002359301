import React, { memo, useState } from 'react';
import styles from './AddData.module.scss';
import Modal from './../../../Modal';
import { Input, Textarea, Button, Select2Async, Group, Select2Multiple, Select2 } from './../../../Form';
import { FieldTypes } from './../FieldTypes';
import internalApi from '../../../../utils/internalApi';
import { generateLookupID } from '../../../../utils/common';
import { getRecordsForLookup } from '../../../../actions/library';
import toast from 'utils/toast';

const AddData = ({ value = {}, onClose, onSaveData, schema }) => {
	const [state, setState] = useState(value);

	const saveValue = (field) => (_value) => {
		// console.log(field,_value);
		let currentState = { ...state };
		currentState[field.id] = _value;
		setState(currentState);
	};

	const saveData = () => {
		let keys = Object.values(state)?.filter?.(v => v?.trim?.());
		if(keys?.length > 0){
			// console.log('saving data -> ', state);
			onSaveData({ ...state });
			onClose();
		}else{
			toast('Cannot save an empty form.','error');
		}
	};

	let isEditMode = value && Object.keys(value).length > 0 ? true : false;

	// console.log('schema-> ', schema);
	return (
		<Modal isOpen={true} title={isEditMode ? `Edit` : `Add`} onClose={onClose}>
			<div className={styles.root}>
				{schema &&
					schema.fields &&
					schema.fields.map((field, fIndex) => (
						<div key={fIndex}>
							{/* <div>{field.label}</div> */}
							<Group label={field.label}>
								{field.type === FieldTypes.SINGLE_LINE_TEXT && <Input value={state[field.id] || ''} onChange={saveValue(field)} />}
								{field.type === FieldTypes.MULTI_LINE_TEXT && <Textarea value={state[field.id] || ''} onChange={saveValue(field)} />}
								{field.type === FieldTypes.DROPDOWN && (
									<React.Fragment>
										{field.isMultiple && (
											<Select2Multiple
												clearable
												field={{ name: field.label }}
												options={field.dropdownOptions && field.dropdownOptions.split(',')}
												value={state[field.id] || ''}
												onChange={saveValue(field)}
											/>
										)}
										{!field.isMultiple && (
											<Select2
												options={field.dropdownOptions && field.dropdownOptions.split(',')}
												field={{ name: field.name }}
												value={state[field.id] || ''}
												onChange={saveValue(field)}
												fixedPosition={false}
											/>
										)}
									</React.Fragment>
								)}
								{field.type === FieldTypes.LIBRARY_LOOKUP && (
									<Select2Async
										isMulti={false}
										clearable
										field={{ name: field.label }}
										promiseOptions={(inputValue) => {
											return new Promise((resolve) => {
												if (field.libraryId === 'users') {
													internalApi.get('user/list', { params: { search: inputValue } }).then((res) => {
														let users = [];
														if (res.payload && res.payload.items && res.payload.items.records && Array.isArray(res.payload.items.records)) {
															users = res.payload.items.records.map((u) => {
																// const pickUser = _.pick(u, field.lookupFields);
																const label = u.fullName;
																// const label = Object.keys(pickUser).map(uk => pickUser[uk]).join(' ');
																const userID = generateLookupID(u.id, label);
																return {
																	value: userID,
																	label: label,
																};
															});
														}

														return resolve(users);
													});
												} else {
													if (field.fieldId) {
														let search = '';
														let selectedItems = null;
														if (typeof inputValue === 'string') {
															search = inputValue;
														} else if (typeof inputValue === 'object' && Array.isArray(inputValue)) {
															selectedItems = inputValue;
														}
														getRecordsForLookup({ libraryId: field.libraryId, lookupFields: field.fieldId.split(','), search, selectedItems, limit: 50 }).then((res) => {
															return resolve(res.payload.items);
														});
													} else {
														return resolve([]);
													}
												}
											});
										}}
										value={state[field.id] || ''}
										onChange={saveValue(field)}
									/>
								)}
							</Group>
						</div>
					))}
				<div style={{ width: 200, display: 'flex' }}>
					<Button onClick={onClose} style={{ marginRight: 10 }}>
						Cancel
					</Button>
					<Button onClick={saveData} primary>
						Save
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default memo(AddData);
