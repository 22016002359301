import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'react-fa';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Button, Checkbox } from '../Form';

import _ from 'lodash';

import './component.scss';

class DropDownWidget extends React.PureComponent {
	get fields() {
		return this.props.fields.filter((i) => _.isString(i.text));
	}

	onChange = (fieldId) => (value) => {
		this.props.onChange(fieldId, value);
	};

	onCheckAll = () => {
		this.fields
			.filter((i) => i.hidden)
			.map((item) => {
				this.props.onChange(item.id, true);
			});
	};

	onUncheckAll = () => {
		this.fields
			.filter((i) => !i.hidden)
			.map((item) => {
				this.props.onChange(item.id, false);
			});
	};

	render() {
		const { label, style, labelStyle = {}, contentStyle = {}, outerStyle = {} } = this.props;

		return (
			<Dropdown style={style}>
				<DropdownTrigger>
					<Button primary style={labelStyle}>
						{label}
					</Button>
				</DropdownTrigger>
				<DropdownContent style={outerStyle}>
					<ul>
						<li onClick={this.onCheckAll}>
							<Icon name="check" />
							Check All
						</li>
						<li onClick={this.onUncheckAll}>
							<Icon name="times" />
							Uncheck All
						</li>
					</ul>
					<ul style={contentStyle}>
						{this.fields.map((item, key) => (
							<li key={key}>
								<Checkbox
									field={{
										name: `hidden-col-${item.value}`,
									}}
									checked={!item.hidden}
									onChange={this.onChange(item.id)}
									label={item.text}
								/>
							</li>
						))}
					</ul>
				</DropdownContent>
			</Dropdown>
		);
	}
}

DropDownWidget.propTypes = {
	label: PropTypes.string,
	fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onChange: PropTypes.func.isRequired,
	style: PropTypes.shape({
		position: PropTypes.string,
	}),
};

DropDownWidget.defaultProps = {
	label: '',
	style: {},
};

export default DropDownWidget;
