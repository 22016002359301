import createAction from '../utils/createAction';

export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const PUSH_ERROR = 'PUSH_ERROR';

const clearAlerts = () => (dispatch) => {
	dispatch(createAction(CLEAR_ALERTS));
};

const pushError = (error) => (dispatch) => {
	const message = error || 'Something went wrong';

	dispatch(
		createAction(PUSH_ERROR, {
			message,
		}),
	);
};

export { clearAlerts, pushError };
