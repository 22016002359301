import React from 'react';

import Validator from '../../utils/form-validator';
import toast from '../../utils/toast';
import storage from '../../utils/storage';
import internalApi from '../../utils/internalApi';

import { Link } from 'react-router-dom';
import FormView from '../../lib/FormView';
import { Input, Group, Button } from '../../components/Form';
import { Flex, Col, Padding, ToastContainer, Image, Text } from '../../components';

import imgLogo from '../../assets/images/logo.png';

class ForgotPassword extends FormView {
	state = {
		form: {
			email: '',
		},
		errors: {},
	};

	validator = new Validator({
		email: {
			required: true,
			message: 'Email is required',
		},
	});

	isValid() {
		const [isValid, errors] = this.validator.isValid(this.state.form);
		this.setState({
			errors,
		});
		return isValid;
	}

	static getDerivedStateFromProps(props) {
		storage.set('companyAlias', props.match.params.companyAlias);
		return {};
	}

	onSend = (e) => {
		e.preventDefault();

		if (!this.isValid()) {
			toast('Email is required', 'error');
			return;
		}

		internalApi
			.post(
				{
					server: 'auth',
					path: '/forgot-password',
				},
				this.state.form,
			)
			.then(({ payload }) => {
				toast(payload.message, 'success');
			});
	};

	render() {
		return (
			<Flex xs={{ justify: 'center', align: 'center', height: '100vh' }}>
				<Col xs={{ size: 3 }}>
					<ToastContainer />
					<Padding padding={{ bottom: 20 }}>
						<Image src={imgLogo} />
					</Padding>
					<Group label="Email">
						<Input lg bordered value={this.getField('email')} onChange={this.onChange('email')} />
					</Group>
					<Button lg primary onClick={this.onSend}>
						Retrieve Password
					</Button>
					<Padding padding={{ top: 15 }}>
						<Flex xs={{ align: 'center', justify: 'center' }}>
							<Link to={`/${this.props.match.params.companyAlias || storage.get('companyAlias')}`}>
								<Text fontSize={16} align="right" color="#0052CC">
									Back to Login
								</Text>
							</Link>
						</Flex>
					</Padding>
				</Col>
			</Flex>
		);
	}
}

export default ForgotPassword;
