import React from 'react';

import Padding from '../../Padding';
import Text from '../../Text';
import Flex, { Col } from '../../Flex';
import cs from './component.module.scss';

class Footer extends React.PureComponent {
	render() {
		return (
			<div className={cs.root} style={{ position: 'fixed', bottom: 0, background: '#fff', zIndex: 1 }}>
				<Padding padding={{ top: 10, bottom: 10, left: 10 }}>
					<Flex xs={{ align: 'center' }}>
						<Col xs={{ size: 10, right: 30 }} sm={{ size: 9 }}>
							<Text fontSize={12} fontWeight={300}>
								<span style={{ fontWeight: 700 }}> Copyright </span>© {new Date().getFullYear()} Continuity Innovations, LLC. All rights reserved.
							</Text>
						</Col>
					</Flex>
				</Padding>
			</div>
		);
	}
}

export default Footer;
