import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './ManageEvent.module.scss';
import { Modal } from '../../../../../components';
import { Button, ButtonDelete } from '../../../../../components/Form';
import { useSelector } from 'react-redux';
import { deleteLibraryRecords } from '../../../../../actions/library';

const ManageEvent = ({ id, onClose, loadAllEvents }) => {
	const history = useHistory();
	const libraries: Array<any> = useSelector((state: any) => state?.library?.get?.('libraries')?.get?.('data')?.toJS?.());

	const edit = () => {
		history.push(`/library/calendar/update/${id}`);
	};

	const deleteEvent = () => {
		const calendarLibraryId = libraries?.find?.((l) => l?.alias === 'calendar')?.id;
		deleteLibraryRecords(calendarLibraryId, id)().then((deleted) => {
			loadAllEvents();
		});
	};

	return (
		<Modal maxWidth={250} isOpen={true} onClose={onClose}>
			<div className={styles.root}>
				<div style={{ margin: 10 }}>
					<Button onClick={edit}>Edit</Button>
				</div>
				<div style={{ margin: 10 }}>
					<ButtonDelete onClick={deleteEvent}>Delete</ButtonDelete>
				</div>
			</div>
		</Modal>
	);
};

export default memo(ManageEvent);
